<template>
  <div
    class="box is-relative"
    :style="backgroundStyle"
    :class="[!count ? 'has-background-success' : 'has-background-primary']"
  >
    <div class="columns is-centered is-vcentered is-mobile is-multiline">
      <div class="column is-narrow has-text-centered is-12-mobile">
        <p class="title is-5 is-size-6-mobile has-text-white">
          <span class="icon">
            <i class="fal" :class="icon" />
          </span>
          <span v-html="title"></span>
        </p>
      </div>
      <div class="column is-narrow has-text-centered is-12-mobile" v-if="count">
        <button
          class="button is-size-7-mobile is-rounded is-soft is-fullwidth"
          @click="view"
        >
          View
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { xor } from 'lodash'
export default {
  name: 'StockviewActionBar',
  props: {
    count: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters']),
    title() {
      const verb = this.count === 1 ? 'is' : 'are'
      const count = this.count
        ? this.count
        : this.$mq === 'mobile'
        ? 'No'
        : 'no'
      const noun = this.count === 1 ? 'vehicle' : 'vehicles'
      if (this.$mq !== 'mobile') {
        return `There ${verb} ${count} ${noun} you need to action`
      } else {
        return `${count} ${noun} require action`
      }
    },
    backgroundStyle() {
      if (!this.count) return {}

      const colour = this.$experian ? 'experian' : 'primary'
      const path =
        this.$mq === 'mobile'
          ? 'none'
          : 'url(' +
            require(`core/img/background/exclamation-${colour}.svg`) +
            ')'
      return {
        backgroundImage: path,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto'
      }
    },
    icon() {
      return !this.count ? 'fa-check-circle' : 'fa-exclamation-triangle'
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['updateFilters']),
    track() {
      const page = this.$route.name === 'stockview' ? 'dashboard' : 'stock'
      this.$mxp.track(`stockview_${page}_action_bar`, { count: this.count })
    },
    view() {
      this.track()
      const currentFilters = this.filters.pricePosition
      const warningFilters = ['UnderPriced', 'OverPriced']
      const notViewing = xor(currentFilters, warningFilters).length

      if (notViewing) {
        const type = 'pricePosition'
        const payload = ['UnderPriced', 'OverPriced']
        this.updateFilters({ filters: [{ type, payload }], count: 1, type })
      }

      if (this.$route.name === 'stockview')
        this.$router.push({ name: 'stockview-stock' }).catch(() => {})
    }
  }
}
</script>

<style lang="sass" scoped>
.close
  position: absolute
  top: calc(1.25rem / 2)
  right: calc(1.25rem / 2)
</style>
